<template>
  <div class="wrap" style="padding:0.10rem">
    <h2 style="font-size:0.17rem;">{{noticeTitle}}</h2>
    <div class="content" v-html="noticeContent"></div>
    <div class="read-bottom">
      <div class="read-item">已阅人员</div> 
      <div>{{JSON.stringify(readUserList)}}</div>
    </div>
  </div>
</template>
<script>
import {getNoticeDetail,getReadUserList} from '@/api/index'
export default {
  data() {
    return {
      noticeTitle: '',
      noticeContent: '',
      loading:false,
      readUserList:[],
    }
  },
  computed:{
    noticeId(){
      return this.$route.query.noticeId
    }
  },
  created() {
    this.getNoticeDetail();
    this.getReadUserList();
  },
  methods: {
    getNoticeDetail() {
      this.loading = true;
      getNoticeDetail(this.noticeId).then(res => {
        if(res.code == 200) {
          this.noticeTitle = res.data.noticeTitle
          this.noticeContent = res.data.noticeContent
        }
        this.loading = false;
      })
    },
    getReadUserList() {
      this.loading = true;
      this.readUserList = [];
      getReadUserList({noticeId : this.noticeId}).then(res => {
        if(res.code == 200) {
          const dataList = res.data
          dataList.forEach(res => {
            this.readUserList.push(res.realName);
          });
        }
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrap {
    background: #F5F5F5;
    v-deep p{
      margin:0rem
    }
  }
  .read-item{
    margin:0.15rem 0rem;
    font-size:0.14rem;
    color:rgb(136, 135, 135)
  }
  .read-bottom{
    margin:0.20rem 0rem; 
    border-top:0.001rem solid rgb(213 213 213)
  }
</style>
